<template>
  <div>
    <nav-bar v-if="showBackBtn" @onBack="handleBack"></nav-bar>
    <div v-if="isClose">
      <div v-if="validateParams" class="kl-research-task">
        <h3>{{ needObscure ? OBSCURE_NAME : taskName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div class="task-upload-wrapper">
          <div class="task-info">
            <div v-for="(item, index) in keywordList" :key="index" class="task-info-item">
              <span>{{ item.key }}：</span><span class="item-value">{{ item.value }}</span>
              <van-button v-if="item.needCopy" v-bind="btnConfig" @click="$utils.copyText(item.value)">
                复制
              </van-button>
            </div>
          </div>
          <div class="line"></div>
          <div class="task-upload-box">
            <div v-if="!isTaskSubmit">
              <p class="upload-tips">请上传图片，不超过10M/张</p>
              <image-collection
                v-if="uploadOptions"
                ref="skuImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :task-id="urlParams.userTaskId"
                :check-repeat="true"
                :is-show-add-btn="true"
                :is-check-time="true"
                :is-check-width="false"
                :job-start-time="jobStartTime"
                :max-image-size="maxImageSize"
                btn-size="small"
              />
              <div v-else> {{ uploadTips }} </div>
            </div>
            <div v-else class="submited">
              任务已提交
            </div>
          </div>
        </div>
        <div class="submit-btn" v-if="!isTaskSubmit">
          <van-button
            class="task-submit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
          >点我提交</van-button>
        </div>

        <van-dialog
          title="提交后不可更改，确认提交"
          v-model="isShowConfirm"
          confirm-button-text="确认提交"
          cancel-button-text="返回补充"
          :show-cancel-button="true"
          @confirm="confirmSubmit"
          @cancel="cancelSubmit"
        >
          <div class="confirm-content">
            <p>任务名称：{{ taskName }}</p>
            <p>提交数量：{{ waitForSaveList.length }}</p>
          </div>
        </van-dialog>
      </div>
      <params-error v-else />
    </div>
    <van-empty v-else description></van-empty>
  </div>
</template>

<script>

import ImageCollection from '../ImageCollection/Index';
import NavBar from '../components/nav-bar.vue';
import ParamsError from '../components/paramsError.vue';

import submitFileMixins from '../mixins/submit-file';
import backListMixins from '../mixins/back-list';

import { checkUploadImages } from '../shared';
import { Toast } from 'vant';

import { updateKlResearchStatus, getTaskDetails } from '@/apis'
import { sentryReportError } from '../../../tools/sentry';
import { updateStore } from '@/tools/file-upload'
import { formatDate } from "@/tools/date";
import { getStore } from '@/tools/storage';
import { KL_RESEARCH_TASK } from '@/constant'
import { taskStatusEnum } from '../task-list/config'

const OBSCURE_KEYWORD = '脱敏';

export default {
  name: 'KlResearchTask',
  components: {
    ImageCollection,
    NavBar,
    ParamsError,
  },
  mixins: [submitFileMixins, backListMixins],
  data() {
    return {
      isClose: true,
      needObscure: false,
      OBSCURE_NAME: '录屏事件名称',
      taskName: '',
      effectiveTime: '',
      uploadOptions: null,
      uploadTips: '',
      accept: 'image/*',
      jobStartTime: 0,
      maxImageSize: 1024 * 1024 * 10,
      isShowConfirm: false,
      waitForSaveList: [],
      taskType: '',
      keywordList: [],
      deliveryArea: '',
      companyName: '',
      taskStatus: '',
      loading: false,
      btnConfig: {
        plain: true,
        hairline: true,
        color: '#1989fa',
        size: 'mini',
        type: 'primary'
      }
    }
  },
  computed: {
    isTaskSubmit() {
      return this.getTaskStatusStore() || this.taskStatus === taskStatusEnum.SUBMITED
    }
  },
  methods: {
    /**
     * @description: 弹窗确定提交
     * @return {*}
     */    
    async confirmSubmit() {
      try {
        await this.fetchTaskNew(this.submitInfo, this.submitImgs, 'klResearch');
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 弹窗取消提交
     * @return {*}
     */    
    cancelSubmit() {
      this.loading = false;
    },
    /**
     * @description: 获取任务详情
     * @return {*}
     */    
    async getJobDetails() {
      try {
        const { userTaskId } = this.urlParams;
        const res = await getTaskDetails({ userTaskId });
        if (res && res.code === 0) {
          const data = res.data || {};
          const time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          )
          this.needObscure = !!data.isSensitive;
          this.taskName = data.name || '';
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME;
            } else {
              document.title = data.name;
            }
          }

          this.effectiveTime = `有效时间至:${time}`
          this.taskType = data.taskType || '';
          this.keywordList = data.keywordList || []

          this.jobStartTime = data.jobStartTime || 0;
          this.deliveryArea = data.deliveryArea || '';
          this.companyName = data.companyName || '';
          this.taskStatus = data.status
        } else {
          Toast.fail('获取任务详情接口失败')
        }
      } catch(e) {
        Toast.fail(e.message || '获取任务详情接口失败')
        sentryReportError(e, {
          scene: '任务详情获取失败'
        });
      }
    },
    /**
     * @description: 提交文件
     * @return {*}
     */    
    async handleSubmit() {
      if (this.loading) return;

      this.loading = true;

      try {
        const imgs = this.$refs.skuImg.getImagesList();
        const submitInfo = await checkUploadImages(imgs);
        if (submitInfo) {
          const verifyValid = await this.$refs.skuImg.checkFileValid();
          if (!verifyValid.valid) {
            Toast.fail(
              verifyValid.messageType
              ? verifyValid.message
              : `${verifyValid.message}, 请重新上传后提交`
            );
            this.loading = false;
            return;
          }

          this.submitInfo = submitInfo;
          this.submitImgs = imgs;

          this.waitForSaveList = imgs.filter(item => !item.saveStatus)

          this.$nextTick(() => {
            this.isShowConfirm = true;
          });
        } else {
          this.loading = false;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    /**
     * @description: 保存KL任务上传状态
     * @return {*}
     */    
    async updateStatus() {
      try {
        const userTaskId = this.urlParams?.userTaskId
        updateStore(KL_RESEARCH_TASK, userTaskId)
        await updateKlResearchStatus({
          userTaskId
        })
      } catch(e) {
        console.log('状态更新失败')
      }
    },
    /**
     * @description: 获取本任务是否存在已提交过的缓存记录
     * @return {*}
     */    
    getTaskStatusStore() {
      try {
        const submitedList = getStore(KL_RESEARCH_TASK) || []
        return Array.isArray(submitedList) && submitedList.includes(this.urlParams?.userTaskId);
      } catch(e) {
        console.log(e);
        return false;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.kl-research-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .task-upload-wrapper {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .task-info {
      .task-info-item {
        padding: 5px 0;
        & > span {
          display: inline-block;
          vertical-align: top;
          line-height: 26px;
          &.item-value {
            padding-right: 5px;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #999;
      margin-top: 15px;
    }
    .task-upload-box {
      .upload-tips {
        color: #888;
        font-size: 12px;
        margin-left: 0.2rem;
      }
      .submited {
        color: #888;
        font-size: 14px;
        text-align: center;
        line-height: 3;
      }
    }
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .task-submit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
  }
  .confirm-content {
    text-align: center;
  }
}
</style>